<template>
  <div class="container">
    <navbar></navbar>
    <div class="content p-15">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          @load="onLoad"
        >
          <div
            class="item bg-color8 m-b-20"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="d-flex ai-center jc-between bold fs-xxl px-10 py-15">
              <p v-if="item.nft">
                {{ lang == "zh" ? item.nft.nft_name : item.nft.nft_name_en }}
              </p>
              <p>{{ item.price | cutZero }} BNB</p>
            </div>
            <div class="bg-white item px-10 py-15">
              <div class="d-flex ai-center jc-between m-b-20">
                <p class="t-color2">{{ $t("market.game") }}</p>
                <p class="t-color9 bold" v-if="item.game">
                  {{ lang == "zh" ? item.game.name : item.game.name_en }}
                </p>
              </div>
              <div class="d-flex ai-center jc-between m-b-20">
                <p class="t-color2">{{ $t("market.rate") }}</p>
                <p class="t-color9 bold">{{ item.discount }}</p>
              </div>
              <div class="d-flex ai-center jc-between m-b-20">
                <p class="t-color2">{{ $t("market.hash") }}</p>
                <div class="d-flex ai-center">
                  <p class="m-r-5 t-color9 bold">
                    {{ item.tx_hash | plusXing(10, 6) }}
                  </p>
                  <van-image
                    width="15px"
                    height="15px"
                    :src="require('@/assets/copy.png')"
                    v-clipboard:copy="item.tx_hash"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  />
                </div>
              </div>
              <div class="d-flex ai-center jc-between">
                <p class="t-color2">{{ $t("market.buytime") }}</p>
                <p class="t-color9 bold">{{ item.created_at }}</p>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [],
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    //复制成功
    onCopy() {
      this.$toast({
        message: this.$t("public.copySuc"),
        duration: 1000,
      });
    },
    //复制失败
    onError() {
      this.$toast({
        message: this.$t("public.copyCancel"),
        duration: 1000,
      });
    },
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        this.finished = false;
        this.getList();
        this.pageNumber++;
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
    getList() {
      let that = this;
      this.loading = true;
      this.$axios
        .get(this.$api.buyLog, {
          page: this.pageNumber,
          page_size: 8,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
            that.loading = false;
            that.refreshing = false;
            let arr = res.data.list;
            that.totalPage = res.data.totalPage; // 总条数
            if (arr == null || arr.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            that.list.push(...arr);
            // 如果当前页数>=总条数，不再触发滚动加载
            if (that.pageNumber >= that.totalPage) {
              that.finished = true;
            }
          } else {
            this.loading = false;
            that.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 10px;
}
</style>